<template>
  <div :class="$style.container">
    <div :class="$style.wrapper">
      <el-button
        type="primary"
        :class="$style.openFormButton"
        @click="isShow = !isShow"
        >{{
          !isShow ? 'Открыть создание каталога' : 'Закрыть создание каталога'
        }}</el-button
      >
      <el-form
        v-if="isShow"
        ref="createCatalogForm"
        :model="createCatalogForm"
        :rules="createCatalogRules"
        :class="$style.createCatalogForm"
      >
        <el-form-item label="Имя каталога" prop="name">
          <el-input
            v-model="createCatalogForm.name"
            placeholder="Введите имя каталога"
          >
          </el-input>
        </el-form-item>
        <el-button type="primary" @click.prevent="createCatalog"
          >Создать каталог</el-button
        >
      </el-form>
    </div>
    <el-table :data="catalogs" stripe @sort-change="onSortChange">
      <el-table-column label="Название" width="320" prop="name" sortable>
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.name"
            placeholder="Введите название каталога"
            :class="$style.input"
          />
          <div v-if="!scope.row.name" :class="$style.error">
            {{ errors.updateName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Каталог по умолчанию" prop="is_default" sortable>
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.isDefault"
            :disabled="defaultCatalogId !== scope.row.id && !!defaultCatalogId"
          />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="updateCatalog(scope.row)"
            >Редактировать</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="Количество продуктов" prop="productsCount" />
      <el-table-column>
        <template slot-scope="scope">
          <router-link
            :to="
              getRoute({
                route: $options.ADDSELLERS_ADMIN_ROUTES.CATALOGS.UPDATE,
                params: { id: scope?.row?.id },
              })
            "
            >Перейти в каталог</router-link
          >
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons name="trash" @delete="deleteCatalog(scope?.row)" />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      background
      layout="prev, pager, next"
      :page-size="query.limit"
      :total="count"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getList"
    />
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import { ADDSELLERS_ADMIN_ROUTES, getRoute } from '@/constants/routing'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  components: { ActionButtons },
  mixins: [notifications],
  ADDSELLERS_ADMIN_ROUTES,
  data() {
    return {
      defaultCatalogId: '',
      catalogs: [],
      isShow: false,
      error: '',
      createCatalogForm: {
        name: '',
      },
      query: {
        limit: 10,
        page: 1,
        orderBy: 'name',
        orderDir: 'desc',
      },
      count: 0,
      createCatalogRules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
      },
      errors: {
        updateName: '',
      },
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.query.limit)
    },
  },
  async created() {
    this.sortingElTableTypes = {
      descending: 'desc',
      ascending: 'asc',
    }
    await this.getList()
  },
  methods: {
    async onSortChange({ prop, order }) {
      if (order && prop) {
        this.query.orderBy = prop
        this.query.orderDir = this.sortingElTableTypes[order]
      }
      this.page = 1
      await this.getList()
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.CatalogsActions.getList({
          ...this.query,
        })

      loading.close()

      if (error) return

      this.catalogs = value?.data ?? []
      this.count = value?.meta?.count ?? 0
      this.defaultCatalogId = value?.meta?.defaultCatalogId
    },
    async createCatalog() {
      this.$refs.createCatalogForm.validate(async (valid) => {
        if (!valid) return

        const loading = this.$loading({
          lock: true,
        })

        const { error } = await delivery.AddwineCore.CatalogsActions.create({
          ...this.createCatalogForm,
        })

        loading.close()

        if (error) {
          this.showNotification('Ошибка создания каталога', 'error')
          return
        }

        this.clearForm()
        await this.getList()
      })
    },
    async deleteCatalog(catalog) {
      const isConfirm = confirm(
        `При удалении каталога все товары в этом каталоге будут удалены. Вы уверены, что хотите удалить каталог "${catalog.name}"?`,
      )

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddwineCore.CatalogsActions.delete(
        catalog.id,
      )

      loading.close()

      if (error) {
        this.showNotification('Ошибка удаления каталога', 'error')
        return
      }

      await this.getList()
    },
    async updateCatalog(catalog) {
      this.clearErrors()

      if (!this.isUpdateFormValid(catalog)) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddwineCore.CatalogsActions.update(
        catalog.id,
        {
          ...catalog,
        },
      )

      loading.close()

      if (error) {
        this.showNotification('Ошибка редактирования каталога', 'error')
        return
      }

      this.showNotification('Каталог успешно отредактирован', 'success')
      await this.getList()
    },
    clearForm() {
      this.createCatalogForm.name = ''
    },
    clearErrors() {
      this.errors.updateName = ''
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
    isUpdateFormValid(catalog) {
      let isValid = true

      if (!catalog.name) {
        isValid = false
        this.errors.updateName = 'Пожалуйста, заполните это поле'
      }

      return isValid
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  overflow: hidden;
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;

    .openFormButton {
      max-width: max-content;
      max-height: 5rem;
    }

    .createCatalogForm {
      background: $white;
      padding: 2rem;
      max-width: 30rem;
      margin-bottom: 3rem;
      position: relative;
      h2 {
        color: $black-gray;
        margin-bottom: 1.5rem;
      }
    }
  }

  .error {
    color: $error;
  }

  .pagination {
    @include pagination;
  }
}
</style>
